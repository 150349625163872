<template>
  <div class="mall">
    <div class="mallBanner">
      <el-row>
       
          <ul class="bannerMenu" @mouseleave="actived = false">
            <li v-for="(i,index) in menuGoods" :key="i.id" class="li" @mouseover="handleMenu(index)">
              <span>{{i.name}}</span>
              <i class="el-icon-arrow-right"></i>
            </li>
            <li class="secondMenu" v-if="actived">
              <div class="secondMenuList" v-for="item in menuGoodsList" :key="item.id"  @click="getGoodsListByType(item)">
                <div class="secondMenuListBox">
                   <!-- <img :src="item.extra" alt=""> -->
                  <span style="color:#333">{{item.name}}</span> 
                </div>
              </div>
            </li>
            <li class="threadMenu" v-if="actived">
              <div class="secondMenuList" v-for="item in menuGoodsListNext" :key="item.id"  @click="toGoodsDetail(item.id)">
                <!-- <div class="secondMenuListBox"> -->
                   <img :src="item.image" alt="">
                  <!-- <span style="color:#333">{{item.storeName}}</span>  -->
                <!-- </div> -->
              </div>
            </li>
          </ul>
        <el-col :span="24" class="bannerBan">
          <el-carousel height="450px" class="indexBanner">
            <el-carousel-item v-for="(item, index) in imgList" :key="index">
              <img :src="item.img" alt="" style="width: 100%; height: 100%" />
            </el-carousel-item>
          </el-carousel>
        </el-col>
      </el-row>
    </div>
    <el-carousel height="50px" direction="vertical" class="indexNotice">
        <el-carousel-item v-for="(item, index) in $store.getters.rollList" :key="index">
          <h3 class="medium">
            <span>网站公告：</span> <span>{{ item.title }}</span>
            <span>{{ item.createTime }}</span>
            <span @click="handleCommandAS" style=" cursor: pointer;">更多<i class="el-icon-arrow-right"></i></span>
          </h3>
        </el-carousel-item>
      </el-carousel>
      <!-- 新品列表 -->
      <div class="hot">
          <div class="hotRight">
              <div class='elCol' :span="4" v-for="(item,index) in newList" :key="index" @click="toGoodsDetail(item.id)">
                  <img :src="item.image" alt="" style="height:220px;width:220px" @click="toGoodsDetail(item.id)">
                  <p class="title">{{item.storeName}}</p>
                  <p class="tips" v-if="vipLevel==1"><span>{{item.priceLevelFour}}</span> 元/{{item.unitName}}</p>
                  <p class="tips" v-if="vipLevel==2"><span>{{item.priceLevelOne}}</span>元/{{item.unitName}}</p>
                  <p class="tips" v-if="vipLevel==3"><span>{{item.priceLevelTwo}}</span>元/{{item.unitName}}</p>
                  <p class="tips" v-if="vipLevel==4"><span>{{item.priceLevelThree}}</span>元/{{item.unitName}}</p>
                  <p class="tips" v-if="vipLevel==5"><span>{{item.priceLevelFour}}</span>元/{{item.unitName}}</p>
                  <p class="price">原价{{item.otPrice}}元</p>
              </div>
          </div>
          <div class="lookMore">
            <span @click="lookMore(1)">{{show1?'收起':'+ 查看更多'}}</span> 
          </div>
      </div>
      <div class="line"></div>
      <div class="ad">
          <img :src="require('@/assets/ad/new.jpg')" alt="">
      </div>
       <div class="line"></div>
       <!-- 促销商品 -->
      <div class="hot">
          <div class="hotRight">
              <div class='elCol' :span="4" v-for="(item,index) in cuxiaoList" :key="index" @click="toGoodsDetail(item.id)">
                  <img :src="item.image" alt="" style="height:220px;width:220px" @click="toGoodsDetail(item.id)">
                  <p class="title">{{item.storeName}}</p>
                   <p class="tips" v-if="vipLevel==1"><span>{{item.priceLevelFour}}</span> 元/{{item.unitName}}</p>
                  <p class="tips" v-if="vipLevel==2"><span>{{item.priceLevelOne}}</span>元/{{item.unitName}}</p>
                  <p class="tips" v-if="vipLevel==3"><span>{{item.priceLevelTwo}}</span>元/{{item.unitName}}</p>
                  <p class="tips" v-if="vipLevel==4"><span>{{item.priceLevelThree}}</span>元/{{item.unitName}}</p>
                  <p class="tips" v-if="vipLevel==5"><span>{{item.priceLevelFour}}</span>元/{{item.unitName}}</p>
                  <p class="price">原价{{item.otPrice}}元</p>
              </div>
          </div>
          <div class="lookMore">
            <span @click="lookMore(2)">{{show2?'收起':'+ 查看更多'}}</span> 
          </div>
      </div>
      <div class="line"></div>
      <div class="ad">
          <img :src="require('@/assets/ad/remenbangdan.jpg')" alt="">
      </div>
       <div class="line"></div>
       <!-- 商品 -->
      <div class="hot">
          <div class="hotRight">
              <div class='elCol' :span="4" v-for="(item,index) in jinpinList" :key="index" @click="toGoodsDetail(item.id)">
                  <img :src="item.image" alt="" style="height:220px;width:220px" @click="toGoodsDetail(item.id)">
                  <p class="title">{{item.storeName}}</p>
                   <p class="tips" v-if="vipLevel==1"><span>{{item.priceLevelFour}}</span> 元/{{item.unitName}}</p>
                  <p class="tips" v-if="vipLevel==2"><span>{{item.priceLevelOne}}</span>元/{{item.unitName}}</p>
                  <p class="tips" v-if="vipLevel==3"><span>{{item.priceLevelTwo}}</span>元/{{item.unitName}}</p>
                  <p class="tips" v-if="vipLevel==4"><span>{{item.priceLevelThree}}</span>元/{{item.unitName}}</p>
                  <p class="tips" v-if="vipLevel==5"><span>{{item.priceLevelFour}}</span>元/{{item.unitName}}</p>
                  <p class="price">原价{{item.otPrice}}元</p>
              </div>
          </div>
          <div class="lookMore">
            <span @click="lookMore(3)">{{show3?'收起':'+ 查看更多'}}</span> 
          </div>
      </div>
      <div class="line"></div>
      <div class="ad">
          <img :src="require('@/assets/ad/jingpintuijian.jpg')" alt="">
      </div>
       <div class="line"></div>
      <div class="hot">
          <div class="hotRight">
              <div class='elCol' :span="4" v-for="(item,index) in remenList" :key="index" @click="toGoodsDetail(item.id)">
                  <img :src="item.image" alt="" style="height:220px;width:220px" @click="toGoodsDetail(item.id)">
                  <p class="title">{{item.storeName}}</p>
                  <p class="tips" v-if="vipLevel==1"><span>{{item.priceLevelFour}}</span> 元/{{item.unitName}}</p>
                  <p class="tips" v-if="vipLevel==2"><span>{{item.priceLevelOne}}</span>元/{{item.unitName}}</p>
                  <p class="tips" v-if="vipLevel==3"><span>{{item.priceLevelTwo}}</span>元/{{item.unitName}}</p>
                  <p class="tips" v-if="vipLevel==4"><span>{{item.priceLevelThree}}</span>元/{{item.unitName}}</p>
                  <p class="tips" v-if="vipLevel==5"><span>{{item.priceLevelFour}}</span>元/{{item.unitName}}</p>
                  <p class="price">原价{{item.otPrice}}元</p>
              </div>
          </div>
          <div class="lookMore">
            <span @click="lookMore(4)">{{show4?'收起':'+ 查看更多'}}</span> 
          </div>
      </div>
  </div>
</template>
<script>
import { apiGoodsCate,apiNewGoodsList,
apiCuxiaoGoodsList,apiJingpinGoodsList,apiRemenGoodsList
} from "@/api/goods";
import { aoiGetUserinfo } from "@/api/login";

export default {
  name: "mall",
  data() {
    return {
      actived: false,
      cur: null,
      imgList: [
        { img: require("@/assets/ad/gbanner1.jpg"), date: "2021-05-12" },
        { img: require("@/assets/ad/gbanner2.jpg"), date: "2021-05-12" },
        { img: require("@/assets/ad/gbanner3.jpg"), date: "2021-05-12" },
        { img: require("@/assets/ad/gbanner4.jpg"), date: "2021-05-12" },
        { img: require("@/assets/ad/gbanner5.jpg"), date: "2021-05-12" },

      ],
      menuGoods:[],
      goodsList:[],
      newList: [],
      cuxiaoList:[],
      jinpinList:[],
      remenList:[],
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      menuGoodsListNext: [],
      vipLevel: 1,
    };
  },
  mounted(){
    this.getUserInfo()
    this.onloadGoods()
    this.getNewGoodsList(10)
    this.getCuxiaoList(10)
    this.getJinpinList(10)
    this.getRemenList(10)
  },

  methods: {
     getUserInfo() {
      aoiGetUserinfo().then((res) => {
        if (res.code == 200) {
          console.log(res);
          this.vipLevel = res.data.level
        }else{
          this.vipLevel = 1
        }
      });
    },
    lookMore(index){
      if(index ==1){
        this.show1 = !this.show1
        this.getNewGoodsList(this.show1?99:10)
      }else if(index ==2){
        this.show2 = !this.show2
        this.getCuxiaoList(this.show1?99:10)
      }else if(index ==3){
        this.show3 = !this.show3
        this.getJinpinList(this.show1?99:10)
      }else if(index ==4){
        this.show4 = !this.show4
        this.getRemenList(this.show1?99:10)
      }
    },
    handleCommandAS(){
      this.$router.push({name: 'joiningNotice'})
      this.$store.commit('SET_INFO',['menuIndex','joining'])
    },
    getGoodsListByType(item){
      console.log(item);
      this.https.get('/api/front/products?cid='+item.id).then(res=>{
        console.log(res);
        this.menuGoodsListNext = res.data.data.list
      })
    },
    //获取新品
    getNewGoodsList(limit){
      apiNewGoodsList({limit}).then(res=>{
        if(res.code == 200){
          this.newList =  res.data.list
        }
      })
    },
    //获取促销商品
    getCuxiaoList(limit){
      apiCuxiaoGoodsList({limit}).then(res=>{
        if(res.code == 200){
          this.cuxiaoList =  res.data.list
        }
      })
    },
    //获取精品商品
    getJinpinList(limit){
      apiJingpinGoodsList({limit}).then(res=>{
        if(res.code == 200){
          this.jinpinList =  res.data.list
        }
      })
    },
    //获取热门商品
    getRemenList(limit){
      apiRemenGoodsList({limit}).then(res=>{
        if(res.code == 200){
          this.remenList =  res.data.list
        }
      })
    },
    // 获取商品列表
    onloadGoods(){
      apiGoodsCate({}).then(res=>{
        console.log(res);
        if(res.code == 200){
          this.menuGoods =  res.data
        }
      })
    },

    handleMenu(i) {
      this.cur = i;
      this.actived = true;
      this.menuGoodsList = this.menuGoods[i].child
      this.$forceUpdate()

    },
    toGoodsDetail(id){
      console.log(id);
        this.$router.push({path:'/goodsDetail',query:{id}})
    }
  },
};
</script>
<style lang="scss" scoped>
.line{
    height:10px;
    background-color: #f5f6f7;
}
.mall {
  width: 1200px;
  margin: 0 auto;
  .mallBanner{
    position: relative;
  }
  .bannerMenu {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 250px;
    z-index: 99;
    height: 420px;
    border: 0;
    color: #fff;
    background: rgba(255, 255, 255, 0.5);
    margin: 20px 0;
    .li {
      height: 42px;
      line-height: 42px;
      padding: 0 30px;
      display: flex;
      align-items: center;
      color: #999;
      justify-content: space-between;
      &:hover {
        background-color: orange;
        cursor: pointer;
      }
    }
    .secondMenu {
      z-index: 99;
      position: absolute;
      top: 0;
      left: 250px;
     height: 50px;
      width: 700px;
      background-color: rgba(255,255,255,0.5);
      text-align: left;
      .secondMenuList{
        display: inline-block;
        width: 120px;
        margin-right: 15px;
        line-height: 40px;
        .secondMenuListBox{
          padding-left: 20px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          cursor: pointer;
          &:hover{
            border: 1px solid orange;
          }
          img{
            width: 40px;
            height: 50px;
            border: 1px solid #ccc;
            margin-right: 15px;
          }
        }
      }
    }
    .threadMenu{
      z-index: 99;
      position: absolute;
      top: 50px;
      left: 250px;
      bottom: 0;
      width: 700px;
      background-color: rgba(255,255,255,0.5);
      text-align: left;
      display: flex;
      flex-wrap: wrap;
      .secondMenuList{
        display: inline-block;
        width: 80px;
        height: 120px;
        margin-right: 15px;
        border: 1px solid orange;
        img{
          width: 100%;
          height: 100%;
        }
        // .secondMenuListBox{
        //   padding-left: 20px;
        //   display: flex;
        //   align-items: center;
        //   justify-content: flex-start;
        //   cursor: pointer;
        //   &:hover{
        //     border: 1px solid orange;
        //   }
        //   img{
        //     width: 40px;
        //     height: 50px;
        //     border: 1px solid #ccc;
        //     margin-right: 15px;
        //   }
        // }
      }
    }
  }
  .bannerBan {
    background-color: #ccc;
  }
  .indexNotice,
.container {
  background-color: #fff;
  margin-bottom: 10px;
}
.medium {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 80px;
}
.hot{
    background-color: #fff;
    &Right{
        width: 100%;
        background-color: #f5f6f7;
        text-align: left;
        .elCol{
            background-color: #fff;
            height: 300px;
            width: 220px;
            margin-bottom: 14px;
            margin-right: 25px;
            text-align: center;
            &:nth-child(5n){
              margin-right: 0;
            }
            display: inline-block;
            cursor: pointer;
            p{
                line-height: 26px;
                height: 26px;
                overflow-y: hidden;
            }
            .title{
                color: #333;
            }
            .tips{
                color: #999;
                span{
                  color: red;
                  font-size: 20px;
                }
            }
            .price{
                color: #666;
            }
        }
    }
    .lookMore{
      display: flex;
      justify-content: center;
      span{
        width: 100px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
      }
    }
}
.goods{
    background-color: #f5f6f7;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .goodsItem{
        width: 40%;
        border-radius: 10px;
        border-bottom: 10px solid #f5f6f7;
        background-color: #fff;
        cursor: pointer;
    }
    .goodsItems{
        width: 20%;
        background-color: #fff;
         border-bottom: 10px solid #f5f6f7;
          border-left: 10px solid #f5f6f7;
        border-radius: 6px;
        padding: 15px 0;
        cursor: pointer;
        p{
                line-height: 26px;
            }
            .title{
                color: #333;
            }
            .tips{
                color: #999;
            }
            .price{
                color: orange;
            }
    }
}
.ad{
    background-color: #fff;
    border-radius: 6px;
    padding: 15px;
    img{
        border-radius: 6px;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
}
}
</style>