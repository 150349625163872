import {get, post} from './axios'
import qs from 'qs'
//获取商品分类列表
export const apiGoodsCate = p => get('/api/front/category',p)
//获取商品详情
export const apiGoodsDetail = p => get('/api/front/product/detail/'+p.id)
//商品评论数量
export const apiGoodsCommentCount = p => get('/api/front/reply/config/'+p.id)
//商品评论列表
export const apiGoodsCommentList = p => get('/api/front/reply/list/'+p.id+'?type='+p.type)


//新品列表
export const apiNewGoodsList = (p) => get(`/api/front/index/product/3?page=1&limit=${p.limit}&isShow=1`)
//促销列表
export const apiCuxiaoGoodsList = (p) => get(`/api/front/index/product/4?page=1&limit=${p.limit}&isShow=1`)
//精品列表
export const apiJingpinGoodsList = (p) => get(`/api/front/index/product/1?page=1&limit=${p.limit}&isShow=1`)
//热门列表
export const apiRemenGoodsList = (p) => get(`/api/front/index/product/2?page=1&limit=${p.limit}&isShow=1`)
//推荐商品列表
export const apiHotGoodsList = p => get('/api/front/product/hot',p)
///api/front/product/good  优选商品
export const apiHotGoodList = p => get('/api/front/product/good',p)

//购物车

export const apiAddCart = p =>post('/api/front/cart/save',p)
export const apiGetCartCount = p =>get('/api/front/cart/count?type='+p.type+"&numType="+p.numType)
export const apiGetCartList = p =>get('/api/front/cart/list?isValid=true&page='+p.page+'&limit='+p.limit)
export const apiChangeCartCount = p =>post('/api/front/cart/num',qs.stringify(p) )
export const apiDeleteCartCount = p =>post('/api/front/cart/delete?ids='+p.ids)

//订单列表

export const apiOrderList = p =>get('/api/front/order/list?&page='+p.page+'&limit='+p.limit+'&type='+p.type)
export const apiOrderDetail = p =>get(`/api/front/order/detail/${p.orderId}`)

//生成订单
export const apiCreateOrder = p =>post('/api/front/order/pre/order',p,{headers: { 'content-type': 'application/json' }})

export const apiGetOrderInfo = p =>get('/api/front/order/load/pre/'+p.id)

export const apiCreateNewOrder = p =>post('/api/front/order/create',p)

export const apiGetStoreList = (p) =>post('/api/front/store/list',p)


